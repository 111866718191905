<template>
  <div>
    <template v-if="isMobile()">
      <div class="error--text text-center">
        Chỉ cho phép kiểm nhanh trên máy tính
      </div>
    </template>
    <template v-else>
      <div v-if="availableSession.length > 0" class="mb-5">
        <v-radio-group v-model="receiptItem.id" row dense hide-details>
          <v-radio
            v-for="avaSes in availableSession"
            :key="avaSes.tracking_id"
            :value="avaSes.id"
            class="w-100"
          >
            <template v-slot:label>
              <div class="black--text">
                {{ $t("labels.session") }}
                <b class="error--text">{{ avaSes.tracking_id }}</b>
                -
                {{ $t("labels.receipted") }}
                <span class="primary--text font-weight-medium">{{
                  avaSes.receipted_quantity
                }}</span>
                /
                <span class="success--text font-weight-medium">{{
                  avaSes.request_quantity
                }}</span>
                -
                <small class="font-italic"
                  >{{ $t("labels.note") }}: {{ avaSes.note }}</small
                >
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="mb-5">
        <v-row dense>
          <v-col cols="3">
            <v-autocomplete
              v-model="stamp_size"
              :items="sizeStampOptions"
              :label="$t('labels.stamp_size')"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="code"
              class="c-input-xs"
              type="text"
              :label="placeholder"
              autofocus
              dense
              outlined
              clearable
              hide-details
              @keyup.enter="inputScan"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div class="mb-5">
        <div>
          <div class="d-flex align-center justify-space-between mb-3">
            <div
              :class="{ 'error--text text-decoration-underline': step === 2 }"
            >
              <span class="font-weight-medium">{{ $t("labels.d_size") }}:</span>
              {{ d_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 3 }"
            >
              <span class="font-weight-medium">{{ $t("labels.r_size") }}:</span>
              {{ r_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 4 }"
            >
              <span class="font-weight-medium">{{ $t("labels.c_size") }}:</span>
              {{ c_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 5 }"
            >
              <span class="font-weight-medium"
                >{{ $t("labels.weight_1") }}:</span
              >
              {{ weight }}
            </div>
          </div>
          <div
            class="mb-3"
            :class="{ 'error--text text-decoration-underline': step === 1 }"
          >
            <span class="font-weight-medium">{{ $t("labels.barcode") }}:</span>
            {{ barcode }}
            <span
              class="font-italic primary--text"
              style="font-size: 12px"
              v-if="quantity > 0"
            >
              ({{ $t("labels.scanned") }}: {{ quantity }})
            </span>
          </div>
          <div
            class="mb-3"
            :class="{ 'error--text text-decoration-underline': step === 6 }"
          >
            <span class="font-weight-medium"
              >{{ $t("labels.basket_code") }}:</span
            >
            {{ basket_code }}
          </div>
          <v-row>
            <v-col cols="3" v-if="receiptItem.url_images">
              <ImageViewer :url="receiptItem.url_images" height="106px" />
            </v-col>
            <v-col :cols="receiptItem.url_images ? 9 : 12">
              <div class="mb-3">
                <span class="font-weight-medium">{{ $t("labels.name") }}:</span>
                {{ receiptItem.goods_name || "" }}
              </div>
              <div class="mb-3">
                <span class="font-weight-medium"
                  >{{ $t("labels.goods_description") }}:</span
                >
                {{ receiptItem.goods_description || "" }}
              </div>
              <div class="mb-3">
                <span class="font-weight-medium">{{ $t("labels.size") }}:</span>
                {{ receiptItem.goods_size || "" }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div v-if="lastReceipts && lastReceipts.length > 0">
        <v-alert
          text
          dense
          color="warning"
          border="left"
          style="font-size: 13px"
          class="mb-0 py-1"
        >
          <div
            v-for="(item, index) in lastReceipts"
            :key="`${index}_${item.insert_tracking}`"
            class="d-flex align-center justify-space-between"
            :class="{ 'mb-2': index < lastReceipts.length - 1 }"
          >
            <div>
              {{ $t("labels.barcode") }}: {{ item.customer_goods_barcode }} -
              {{ $t("labels.basket") }}: {{ item.basket_barcode }} -
              {{ $t("labels.stamp") }}: {{ item.sku }}@{{ item.min_uid }}
              <span v-if="item.min_uid !== item.max_uid"
                >→ {{ item.sku }}@{{ item.max_uid }}</span
              >
            </div>
            <div
              class="primary--text cursor-pointer"
              @click="
                printStampAgain(
                  item.id_goods,
                  item.customer_goods_barcode,
                  item.insert_tracking
                )
              "
            >
              <v-icon class="primary--text">mdi-printer</v-icon>
              {{ $t("labels.re_print") }}
            </div>
          </div>
        </v-alert>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
import { downloadPdfFile, downloadExcelFile, isMobile } from "@/libs/helpers";

export default {
  name: "Fast",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  props: {},
  data: () => ({
    sizeStampOptions: [
      {
        text: "30x15",
        value: "30x15",
      },
      {
        text: "32x18",
        value: "32x18",
      },
      {
        text: "50x20",
        value: "50x20",
      },
      {
        text: "50x40",
        value: "50x40",
      },
    ],
    isLoading: false,
    code: null,
    step: 1,
    quantity: 0,
    barcode: null,
    d_size: null,
    r_size: null,
    c_size: null,
    stamp_size: null,
    weight: null,
    basket_code: null,
    receiptItem: {},
    availableSession: [],
    lastReceipts: [],
  }),
  computed: {
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t("labels.barcode");
        case 2:
          return this.$t("labels.d_size");
        case 3:
          return this.$t("labels.r_size");
        case 4:
          return this.$t("labels.c_size");
        case 5:
          return this.$t("labels.weight_1");
        case 6:
          return this.$t("labels.basket_or_barcode");
        default:
          return "--- ERROR ---";
      }
    },
    sumQuantity() {
      let sumRequest = 0;
      let sumReceipted = 0;
      if (!this.availableSession || this.availableSession.length === 0) {
        return { sumRequest, sumReceipted };
      }
      for (let i = 0; i < this.availableSession.length; i++) {
        const aSess = this.availableSession[i];
        sumRequest += aSess.request_quantity;
        sumReceipted += aSess.receipted_quantity;
      }
      return { sumRequest, sumReceipted };
    },
  },
  mounted() {
    this.getLastFastReceipt();
  },
  methods: {
    downloadPdfFile,
    downloadExcelFile,
    isMobile,
    cancel() {
      this.$emit("cancel");
      this.$emit("refreshData");
    },
    restartAll() {
      this.isLoading = false;
      this.code = null;
      this.step = 1;
      this.quantity = 0;
      this.barcode = null;
      this.d_size = null;
      this.r_size = null;
      this.c_size = null;
      this.weight = null;
      this.basket_code = null;
      this.receiptItem = {};
    },
    async printStampAgain(id_goods, customer_goods_barcode, insert_tracking) {
      await this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-stamp`,
        {
          id_goods: id_goods,
          insert_tracking: insert_tracking,
          client_time:  moment().format('HH:mm DD/MM/YYYY')
          // employee_name: employeeName,
        },
        `${this.stamp_size}_${customer_goods_barcode}_${insert_tracking}.pdf`
      );
    },
    async inputScan() {
      if (!this.code) {
        return false;
      }
      //
      switch (this.step) {
        case 1:
          await this.scanBarcode();
          break;
        case 2:
          await this.setDSize();
          break;
        case 3:
          await this.setRSize();
          break;
        case 4:
          await this.setCSize();
          break;
        case 5:
          await this.setWeight();
          break;
        case 6:
          if (this.code.toUpperCase() === this.barcode.toUpperCase()) {
            await this.scanBarcodeAgain();
          } else {
            await this.scanBasket();
          }
          break;
        default:
          this.$vToastify.error(" --- ERROR --- ");
          document.getElementById("error_sound_player").play();
          break;
      }
    },
    async scanBarcodeAgain() {
      if (this.barcode && this.quantity > 0) {
        this.quantity++;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } else {
        this.$vToastify.warning(this.$t("messages.wrong_wrong"));
        document.getElementById("error_sound_player").play();
      }
    },
    async scanBarcode() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-goods-for-fast",
          { code: this.code }
        );
        const receiptItem = data.receiptItem;
        const availableSession = data.availableSession;
        this.receiptItem = { ...receiptItem };
        this.availableSession = [...availableSession];
        this.d_size = this.receiptItem.d_size;
        this.r_size = this.receiptItem.r_size;
        this.c_size = this.receiptItem.c_size;
        if (!this.stamp_size) {
          this.stamp_size = this.receiptItem.stamp_size;
        }
        this.weight = this.receiptItem.weight;
        this.barcode = this.receiptItem.customer_goods_barcode;
        this.quantity = 1;
        if (this.weight) {
          this.step = 6;
        } else {
          this.step = 2;
        }
        document.getElementById("success_sound_player").play();
        this.code = null;
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
        this.code = null;
        this.isLoading = false;
      }
    },
    async scanBasket() {
      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/check-basket-and-create-history",
          {
            code: this.code,
            id: this.receiptItem.id,
            d_size: this.d_size,
            r_size: this.r_size,
            c_size: this.c_size,
            stamp_size: this.stamp_size,
            weight: this.weight,
            quantity: this.quantity,
          }
        );
        await this.downloadExcelFile(
          `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-stamp`,
          {
            id_goods: this.receiptItem.id_goods,
            insert_tracking: data.insertTracking,
            client_time:  moment().format('HH:mm DD/MM/YYYY')
            // employee_name: employeeName,
          },
          `${this.stamp_size}_${this.receiptItem.customer_goods_barcode}_${data.insertTracking}.pdf`
        );
        await this.getLastFastReceipt();
        document.getElementById("success_sound_player").play();
        this.restartAll();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async setDSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.d_size = +this.code;
      this.code = null;
      this.step = 3;
      document.getElementById("success_sound_player").play();
    },
    async setRSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.r_size = +this.code;
      this.code = null;
      this.step = 4;
      document.getElementById("success_sound_player").play();
    },
    async setCSize() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.c_size = +this.code;
      this.code = null;
      this.step = 5;
      document.getElementById("success_sound_player").play();
    },
    async setWeight() {
      if (!Number.isInteger(+this.code) || this.code < 1) {
        this.$vToastify.error(this.$t("messages.not_positive_integer"));
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }
      this.weight = +this.code;
      this.code = null;
      this.step = 6;
      document.getElementById("success_sound_player").play();
    },
    async getLastFastReceipt() {
      const { data } = await httpClient.post(
        "/goods-receipt/v1/get-last-fast-receipt"
      );
      this.lastReceipts = [...data];
    },
  },
};
</script>

<style scoped></style>
